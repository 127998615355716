const GENERATION = {
  FETCH: "GENERATION_FETCH",
  FETCH_ERROR: "GENERATION_FETCH_ERROR",
  FETCH_SUCCESS: "GENERATION_FETCH_SUCCESS",
};

const DRAGON = {
  FETCH: "DRAGON_FETCH",
  FETCH_ERROR: "DRAGON_FETCH_ERROR",
  FETCH_SUCCESS: "DRAGON_FETCH_SUCCESS",
};

const ACCOUNT = {
  FETCH: "ACCOUNT_FETCH",
  FETCH_ERROR: "ACCOUNT_FETCH_ERROR",
  FETCH_SUCCESS: "ACCOUNT_FETCH_SUCCESS",
  FETCH_LOGOUT_SUCCESS: "ACCOUNT_FETCH_LOGOUT_SUCCESS",
  FETCH_AUTHENTICATED_SUCCESS: "ACCOUNT_FETCH_AUTHENTICATED_SUCCESS",
};

const ACCOUNT_DRAGONS = {
  FETCH: "ACCOUNT_DRAGON_FETCH",
  FETCH_ERROR: "ACCOUNT_DRAGON_FETCH_ERROR",
  FETCH_SUCCESS: "ACCOUNT_DRAGON_FETCH_SUCCESS",
};
const ACCOUNT_INFO = {
  FETCH: "ACCOUNT_INFO_FETCH",
  FETCH_ERROR: "ACCOUNT_INFO_FETCH_ERROR",
  FETCH_SUCCESS: "ACCOUNT_INFO_FETCH_SUCCESS",
};
const PUBLIC_DRAGONS = {
  FETCH: "PUBLIC_DRAGONS_FETCH",
  FETCH_ERROR: "PUBLIC_DRAGONS_FETCH_ERROR",
  FETCH_SUCCESS: "PUBLIC_DRAGONS_FETCH_SUCCESS",
};

export {
  GENERATION,
  DRAGON,
  ACCOUNT,
  ACCOUNT_DRAGONS,
  ACCOUNT_INFO,
  PUBLIC_DRAGONS,
};
